const menu = [
    {
      id: 1,
      title: "1. Pizza Margherita",
      category: "Pizzas",
      price: "S: 10.99 | M: 12.99 | L: 14.99 | J: 16.99",
      desc: "...",
    },
    {
      id: 2,
      title: "2. Pizza Toscana",
      category: "Pizzas",
      price: "S: 14.99 | M: 16.99 | L: 19.99 | J: 24.99",
      desc: "Mozza Cheese, Spinach, Olives, Feta Cheese, Crumble Bacon",
    },
    {
      id: 3,
      title: "3. Pizza Carbonara",
      category: "Pizzas",
      price: "S: 14.99 | M: 16.99 | L: 19.99 | J: 24.99",
      desc: "Mozza Cheese, Whipping Cream Base, Crumble Bacon, Parmesan Flakes",
    },
    {
      id: 4,
      title: "4. Pizza Italy",
      category: "Pizzas",
      price: "S: 14.99 | M: 16.99 | L: 19.99 | J: 24.99",
      desc: "Mozza Cheese, Salami, Mushroom, Black Olives, Green Paper, Ham",
    },
    {
      id: 5,
      title: "5. Pizza Roma",
      category: "Pizzas",
      price: "S: 14.99 | M: 16.99 | L: 19.99 | J: 24.99",
      desc: "Mozza Cheese, Ham, Mushroom, Parmesan Flakes, Pepperoni",
    },
    {
      id: 6,
      title: "6. Pizza Calabrian",
      category: "Pizzas",
      price: "S: 15.99 | M: 17.99 | L: 20.99 | J: 25.99",
      desc: "Mozza Cheese, Italian Sausage, Onion, Green Pepper, Jalapenos",
    },
    {
      id: 7,
      title: "7. Pizza Vegetarian",
      category: "Pizzas",
      price: "S: 16.99 | M: 18.99 | L: 20.99 | J: 24.99",
      desc: "Mozza Cheese,Pepper, Mushroom, Zucchini, broccoli, Sauté Veggie ",
    },
    {
      id: 8,
      title: "8. Pizza Tuna",
      category: "Pizzas",
      price: "S: 14.99 | M: 17.99 | L: 20.99 | J: 24.99",
      desc: "Mozza Cheese, Tuna Fish, Onion, Black Olives, Jalapenos ",
    },
    {
      id: 9,
      title: "9. Pizza Hawaiian",
      category: "Pizzas",
      price: "S: 14.99 | M: 17.99 | L: 20.99 | J: 24.99",
      desc: "Mozza Cheese, Ham, Pineapple, Sliced Bacon",
    },
    {
      id: 10,
      title: "10. Pizza Gusto",
      category: "Pizzas",
      price: "S: 16.99 | M: 18.99 | L: 22.99 | J: 25.99",
      desc: "Mozza Cheese, Gorgonzola, Italian Sausage, Parmesan Flakes, Mushroom",
    },
    {
      id: 11,
      title: "11. Pizza Terranova",
      category: "Pizzas",
      price: "S: 16.99 | M: 20.99 | L: 24.99 | J: 28.99",
      desc: "Mozza Cheese, Whipping Cream as a base, Chicken Curry, Onion, broccoli, Pepper",
    },
    {
      id: 12,
      title: "12. Pizza Colosseum",
      category: "Pizzas",
      price: "S: 17.99 | M: 21.99 | L: 25.99 | J: 29.99",
      desc: "Mozza Cheese, Salami, Ham, Pepperoni, Mushroom, Pepper, egg, Crumble bacon",
    },
    {
      id: 13,
      title: "13. Pizza Mediterranian",
      category: "Pizzas",
      price: "S: 14.99 | M: 17.99 | L: 21.99 | J: 24.99",
      desc: "Pepperoni, Tuna, Capers, Anchovies, Olives, Onions",
    },
    {
      id: 14,
      title: "14. Pizza Meat Classic",
      category: "Pizzas",
      price: "S: 20.99 | M: 24.99 | L: 28.99 | J: 32.99",
      desc: "Cheese, Pepperoni, Salami, Italian Sausage, Vienna Sausage, Ground Beef, Slice Bacon",
    },
    {
      id: 15,
      title: "15. Garlic Finger",
      category: "Pizzas",
      price: "S: 5.99 | M: 7.99 | L: 9.99 | J: 11.99",
      desc: "...",
    },
    {
      id: 16,
      title: "16. Pizza Four Cheese",
      category: "Pizzas",
      price: "S: 14.99 | M: 17.99 | L: 20.99 | J: 23.99",
      desc: "Mozza Cheese, Feta Cheese, Gorgonzola, Parmesano Flakes",
    },
    {
      id: 17,
      title: "30. Spaghetti Carbonara",
      category: "Pasta",
      price: "$11.99",
      desc: "Cream Sauce, Crumble Bacon, Yellow Egg with Parmesan Cheese",
    },
    {
      id: 18,
      title: "31. Fusilli Aldo",
      category: "Pasta",
      price: "$10.99",
      desc: "Pepper, Onion, Bolognese with Cream Sauce",
    },
    {
      id: 19,
      title: "32. Fusilli Calabrese",
      category: "Pasta",
      price: "$12.99",
      desc: "Pepper, Onion, Bolognese with Cream Sauce",
    },
    {
      id: 20,
      title: "33. Lasagna Classica",
      category: "Pasta",
      price: "$11.99",
      desc: "Homemade Lasagna",
    },
    {
      id: 21,
      title: "34. Marconi Pavarotti",
      category: "Pasta",
      price: "$12.99",
      desc: "Cream Sauce, Gorgonzola, Bacon, Broccoli, Fresh Tomato",
    },
    {
      id: 22,
      title: "35. Macaroni Rubal",
      category: "Pasta",
      price: "$14.99",
      desc: "Chicken, Mushroom, Garlic, Fresh Tomato, Green Onion, Cream Sauce, Cheese, Baked",
    },
    {
      id: 23,
      title: "35. Macaroni Prashant",
      category: "Pasta",
      price: "$12.99",
      desc: "Mushroom, Fresh Tomato, Chicken Gravy, Cream Sauce, Parmesan Cheese",
    },
    {
      id: 24,
      title: "36. Macaroni Cream Sauce with Parmesan Cheese and Ham",
      category: "Pasta",
      price: "$11.99",
      desc: "...",
    },
    {
      id: 25,
      title: "37. Spaghetti Salmon",
      category: "Pasta",
      price: "$15.99",
      desc: "Salmon, Garlic, Green Onion, Tomato Sauce, Cream Sauce",
    },
    {
      id: 26,
      title: "37. Spaghetti Salmon",
      category: "Pasta",
      price: "$15.99",
      desc: "Salmon, Garlic, Green Onion, Tomato Sauce, Cream Sauce",
    },
    {
      id: 27,
      title: "40. Escalopes Milanese with Fries",
      category: "Carne (Pork Meat)",
      price: "$15.99",
      desc: "With flour, eggs, bread crumb  ",
    },
    {
      id: 28,
      title: "41. Escalopes Cacciatore with Fries",
      category: "Carne (Pork Meat)",
      price: "$17.99",
      desc: "With flour, eggs, bread crumb, Mushroom, Chicken Gravy,  Cream Sauce",
    },
    {
      id: 29,
      title: "42. Escalopes Zingara with Fries",
      category: "Carne (Pork Meat)",
      price: "$17.99",
      desc: "With flour, eggs, Bread Crumb, Pepper, Onion,  Tomato Sauce, Gravy",
    },
    {
      id: 30,
      title: "25. Tiramisu",
      category: "Desserts",
      price: "$7.50",
      desc: "Savoiardi biscuits, Mascarpone cream, Amaretto and Sambuca liquor",
    },
    {
      id: 31,
      title: "26. Cheese Cake",
      category: "Desserts",
      price: "$5.00",
      desc: "Choice your Sauce Caramel or Choco Sauce",
    },
    {
      id: 32,
      title: "27. Cookies",
      category: "Desserts",
      price: "$0.60",
      desc: "...",
    },
    {
      id: 33,
      title: "46. Samosa with Green Chutney",
      category: "Taste of India",
      price: "$2.00 each",
      desc: "...",
    },
    {
      id: 34,
      title: "46. Butter Chicken with Rice or Naan",
      category: "Taste of India",
      price: "$12.99",
      desc: "...",
    },
    {
      id: 35,
      title: "50. Nachos with Trio Cheese (Medium and Large) with Sour Cream and Salsa",
      category: "Nachos",
      price: "S: 7.99 | L: 11.99",
      desc: "...",
    },
    {
      id: 36,
      title: "51. Nachos with Trio Cheese, Green Pepper, Red Onion, Tomato, Jalapenos, Chicken or Beef",
      category: "Nachos",
      price: "S: 14.99 | L: 18.99",
      desc: "...",
    },
    {
      id: 37,
      title: "52.  Nacho with Trio Cheese, Pepper, Red Onion, Jalapenos  with Ragu Sauce",
      category: "Nachos",
      price: "S: 13.99 | L: 16.99",
      desc: "...",
    },
    {
      id: 38,
      title: "55. 10 Wings with Sauce",
      category: "Chicken Wings",
      price: "$14.99",
      desc: "...",
    },
    {
      id: 39,
      title: "56. 15 Wings with Sauce",
      category: "Chicken Wings",
      price: "$19.99",
      desc: "...",
    },
    {
      id: 40,
      title: "57. 3 Chicken strips with Fries",
      category: "Chicken Fingers",
      price: "$7.99",
      desc: "...",
    },
    {
      id: 41,
      title: "58. 5 Chicken strips with Fries",
      category: "Chicken Fingers",
      price: "$9.99",
      desc: "...",
    },
    {
      id: 42,
      title: "59. Fries",
      category: "Chicken Fingers",
      price: "S: $4.99 | M: $6.99",
      desc: "...",
    },
    {
      id: 43,
      title: "60. Onion Rings",
      category: "Chicken Fingers",
      price: "S: $5.99 | M: $8.99",
      desc: "...",
    },
    {
      id: 44,
      title: "61. Poutine Cheese curds with Gravy",
      category: "Chicken Fingers",
      price: "S: $6.99 | M: $9.99",
      desc: "...",
    },
    {
      id: 45,
      title: "62. Poutine Classic Tomato, Onion, Ground Beef and Gravy with cheese curds",
      category: "Chicken Fingers",
      price: "S: $7.99 | M: $11.99",
      desc: "...",
    },
    {
      id: 46,
      title: "63. Ham Burger",
      category: "Burger",
      price: "$12.99, with Fries $15.99",
      desc: "Beef, Lettuce, Tomato, Onion, Mayo, Slice Bacon, Cheddar Cheese",
    },
    {
      id: 47,
      title: "64. Chicken Burger",
      category: "Burger",
      price: "$8.99 with Fries $11.99",
      desc: "Chicken Burger, Lettuce, Tomato, Onion, Mayo, Slice Bacon, Cheddar Cheese",
    },
    {
      id: 48,
      title: "65. Veggie Burger",
      category: "Burger",
      price: "$8.99 with Fries $11.99",
      desc: "Veggie Pattie, Lettuce, Tomato, Onion, Mayo, Cheddar Cheese",
    },
    {
      id: 49,
      title: "111. Salad Roma",
      category: "Salads",
      price: "S: $11.99 | L: $13.99",
      desc: "Lettuce, Pepper, Cucumber, Tuna Fish, Tomato, Carrot, Boil Eggs, Mozza Cheese, House Dressing",
    },
    {
      id: 50,
      title: "112. Mix Salad",
      category: "Salads",
      price: "S: $8.99 | L: $10.99",
      desc: "Lettuce, Carrot, Onion, Tomato, Cucumber,   House Dressing",
    },
    {
      id: 51,
      title: "113. Italia Salad",
      category: "Salads",
      price: "S: $13.99 | L: $15.99",
      desc: "Lettuce, Tomato, Carrot, Chicken, Feta Cheese, Parmesan Flakes, Red Onion",
    },
  ];
  export default menu;