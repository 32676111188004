import React from "react";
import Dessert from "../Dessert";
import Footer from "../Footer";

function Commune() {
  return (
    <div className="App">
      <Dessert />
      <Footer />
    </div>
  );
}

export default Commune;
